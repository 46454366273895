<template>
    <thead>
        <tr>
            <th rowspan="2">
                <CInputCheckbox
                    label=""
                    class="pb-3"
                    name="listSelected"
                    @update:checked="checkAllChange"
                    :checked="checkAll"
                />
            </th>
            <th rowspan="2">
                status
            </th>
            <th colspan="6">
                Stock
            </th>
            <th colspan="3">
                Items
            </th>
            <th colspan="4">
                item_masters
            </th>
        </tr>
        <tr>
            <th>
                ID
            </th>
            <th>
                商品ID
            </th>
            <th>
                shop_id
            </th>
            <th>
                from_shop_id
            </th>
            <th>
                入荷日
            </th>
            <th>
                出荷日
            </th>

            <th>
                ID
            </th>
            <th>
                item_master_id
            </th>
            <th>
                item_code
            </th>
            <th>
                ID
            </th>
            <th>
                name
            </th>
            <th>
                size_id
            </th>
            <th>
                color_id
            </th>
        </tr>
    </thead>
</template>

<script>
export default {
  name: 'StockHeader',
    props: {
        checkAllChange: Function,
        checkAll: Boolean,
    },
  data() {
    return {
      columnNames: [],
      fullPage: false
    }
  },
  methods: {
    // doAjax() {
    //   this.isLoading = true;
    //   // simulate AJAX
    //   setTimeout(() => {
    //     this.isLoading = false
    //   },5000)
    // },
    onCancel() {
      console.log('User cancelled the loader.')
    }
  }
}
</script>
